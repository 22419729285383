<template>
    <section class="pedido-steps">
        <div class="container">
            <div class="row">
                <div class="col-12">						
                    <div class="section-title">
                        <h4>{{ $t("resume") }}</h4>
                        <a @click="volverListado()" href="javascript:void(0)"><img class="d-none d-sm-block" src="assets/img/backspace.svg"></a>
                    </div>
                </div>					
            </div>
            <div class="loading"></div>
            <div class="row mostrarPedido d-none">
                <div class="col-md-12">
                    <div class="confirmado">
                        <div class="section-title p-0">
                            <h5>{{ $t("order_resume") }}</h5>
                        </div>
                        <!-- desktop -->
                        <table class="bg-white d-none d-sm-table">
                            <tr>
                                <th>EAN</th>
                                <th>{{ $t("name") }}</th>
                                <th>Material</th>
                                <th>{{ $t("weight_gross") }}</th>
                                <th>{{ $t("weight_net") }}</th>
                                <th>{{ $t("volume") }}</th> 
                                <th>{{ $t("quantity") }}</th>
                                <th>{{ $t("type") }}</th>
                                <th># Pallet</th>
                            </tr>
                            <tr class="b-top" v-for="(item,index) in this.items" :key="index">
                                <td>{{item.ean}}</td>
                                <td>{{item.sku_name}}</td>
                                <td>{{item.material}}</td>
                                <td>{{item.weight_gross_metric}}</td>
                                <td>{{item.weight_net_metric}}</td>
                                <td>{{item.volume_metric}}</td>
                                <td>{{item.quantity}}</td>
                                <td>{{item.product_type}}</td>
                                <td>{{item.pallet_quantity}}</td>
                            </tr>
                        </table>

                        <!-- mobile -->
                        <div class="pedidosDetalle">
                            <table class="bg-white d-block pedidosTableMobile b-top" v-for="(item,index) in this.items" :key="index">
                                <tr>
                                    <th>EAN</th>
                                    <td>{{item.ean}}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t("name") }}</th>
                                    <td>{{item.sku_name}}</td>
                                </tr>
                                <tr>
                                    <th>Material</th>
                                    <td>{{item.material}}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t("weight_gross") }}</th>
                                    <td>{{item.weight_gross_metric}}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t("weight_net") }}</th>
                                    <td>{{item.weight_net_metric}}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t("volume") }}</th>
                                    <td>{{item.volume_metric}}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t("quantity") }}</th>
                                    <td>{{item.quantity}}</td>
                                </tr>
                                <tr>
                                    <th>{{ $t("type") }}</th>
                                    <td>{{item.product_type}}</td>
                                </tr>
                                <tr>
                                    <th># Pallet</th>
                                    <td>{{item.pallet_quantity}}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="yellow-bg">
                            <div class="inline-table">
                                <span class="pull-left">{{ $t("delivery_date_mandatory") }}: <b>{{order.delivery_date}}</b></span>
                                <!-- <span class="pull-right">Incoterm: <b></b></span> -->
                            </div>
                            <div>
                                <span class="pull-left">{{ $t("ship_to") }}: <b>{{order.delivery_location}}</b></span>
                            </div>
                            <div>
                                <span class="nro-orden">{{ $t("order_number") }}: <b>{{order.order_number}}</b></span>
                            </div>
                            <div class="row mt-5" v-if="order">
                                <div class="col-md-12">
                                    {{ $t("total_weight_net") }}: <b> {{(medicion =='metrico') 
                                    ? order.order.total_weight_net_metric + ' kg' 
                                    : order.order.total_weight_net_imperial + ' lb'}}</b>
                                </div>
                                <div class="col-md-12">
                                    {{ $t("total_weight_gross") }}: <b> {{(medicion =='metrico')
                                    ? order.order.total_weight_gross_metric + ' kg'
                                    : this.order.order.total_weight_gross_imperial + ' lb'}}</b>
                                </div>
                                <div class="col-md-12">
                                    {{ $t("total_volume") }}: <b> {{(medicion =='metrico')
                                    ? order.order.total_volume_metric +  ' m'
                                    : order.order.total_volume_imperial + ' ft'}}<sup>3</sup></b>
                                </div>
                                <div class="col-md-12">
                                    Total Pallets: <b>{{order.order.total_pallet_numbers}}</b>
                                </div>
                            </div>
                            <div class="row mt-5" v-if="order">
                                <div class="col-md-12">
                                    {{ $t("status") }}:
                                    <div class="inline-flex align-center label-content justify-center">
                                        <a href="javascript:void(0)" :class="'label-' + order.order.state">{{order.order.status_name}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 next-btn mt-5 mb-4">
                                <a @click="volverListado()" href="javascript:void(0)" class="btn-white">{{ $t("back_to_list") }}</a>
                            </div>	
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
</template>
<script>
	import { route } from "../../enviroment.js"
    // import axios from 'axios'
    import $ from 'jquery'
    import axiosInstance from '../../main';
    import authMixin from '@/mixins/authMixin';

    export default {
        name: "PedidoDetalle",
        i18n: {
            messages: {
                en: {
                    resume: "Summary",
                    order_resume: "Order Summary",
                    name: "Name",
                    weight_gross: "Gross Weight",
                    weight_net: "Net Weight",
                    volume: "Volume",
                    quantity: "Quantity",
                    type: "Type",
                    delivery_date_mandatory: "Required Delivery Date",
                    ship_to: "Shipping Destination",
                    order_number: "Order Number",
                    total_weight_gross: "Total Gross Weight",
                    total_weight_net: "Total Net Weight",
                    total_volume: "Total Volume",
                    status: "Status",
                    back_to_list: "Back to List"
                },
                es: {
                    resume: "Resumen",
                    order_resume: "Resumen del pedido",
                    name: "Nombre",
                    weight_gross: "Peso Bruto",
                    weight_net: "Peso Neto",
                    volume: "Volumen",
                    quantity: "Cant",
                    type: "Tipo",
                    delivery_date_mandatory: "Fecha requerida de entrega",
                    ship_to: "Lugar de entrega",
                    order_number: "Nro. Orden",
                    total_weight_gross: "Peso Total Bruto",
                    total_weight_net: "Peso Total Neto",
                    total_volume: "Volumen Total",
                    status: "Estado",
                    back_to_list: "Volver al listado"
                }
            }
        },
        props: {
            order_id : String
        },
        data() {
            return {
                route: route,
                items: [],
                order: false,
                medicion: 'metrico'
            }
        },
        mixins: [authMixin],
        async created(){
            await this.validateUserAndSoldTo();
            this.getOrder()
        },
        methods:{
            showAlert(type, title, message) {
                this.$refs.alert.showAlert(
                    type, // There are 4 types of alert: success, info, warning, error
                    message, // Message of the alert
                    title, // Header of the alert
                    { 
                        iconSize: 35, // Size of the icon (px)
                        iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                        position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                    } 
                )
            },
            volverListado(){
                this.$emit("ocultarDetalleChild",false);
            },
            getOrder(){
                axiosInstance.post(this.$woe_api_uri + '/order/items', 
                    {
                        'id':this.order_id
                    }
                ).then((response) => {
                    $('.loading').addClass('d-none');
                    $('.mostrarPedido').removeClass('d-none');
                    this.items = response.data.data.items
                    this.order = response.data.data
                })
                .catch((error) => {
                    this.showAlert('warning',`${this.$t("caution")}!`, error.response.data.error.message);
                });
            }
        }

    }
</script>