export default {
  en: {
    buttons: {
      save: "Save",
      confirm: "Confirm",
      continue: "Continue",
      login: "Login",
      send: "Send",
      sending: "Sending...",
      back_to_home: "Back to home",
      ok: "Ok",
      cancel: "Cancel",
      accept: "Accept",
      close: "Close",
      add: "Add",
      delete: "Delete"
    },
    caution: "Caution",
    success: "Success",
    unexpected_error: "Unexpected error.",
    mccain_privacy_policy_link: "https://www.mccain.com/privacy",
  },
  es: {
    buttons: {
      save: "Guardar",
      confirm: "Confirmar",
      continue: "Continuar",
      login: "Ingresar",
      send: "Enviar",
      sending: "Enviando...",
      back_to_home: "Volver a página de inicio",
      ok: "Entendido",
      cancel: "Cancelar",
      accept: "Aceptar",
      close: "Cerrar",
      add: "Agregar",
      delete: "Eliminar"
    },
    caution: "Atención",
    success: "Éxito",
    unexpected_error: "Error inesperado.",
    mccain_privacy_policy_link: "https://www.mccain.com/privacy/es-la" 
  }
}