<template>
	<header class="bg-black" id="header">
		<div class="container-fluid header">
			<div class="row align-center">
				<div class="col-md-6 text-left">
					<div class="logo">
						<nav class="navbar navbar-expand-lg navbar-light">
							<button class="navbar-toggler" type="button" data-toggle="collapse"
								data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
								aria-expanded="false" aria-label="Toggle navigation">
								<img src="assets/img/menu.svg">
							</button>
							<a class="navbar-brand" :href="route">
								<img src="assets/img/logo.svg">
							</a>
						</nav>
					</div>
				</div>
				<div class="col-md-6 text-right">
					<ul class="navbar-desktop" v-if="!this.loading">
						<li>
							<a class="menu-item" :href="route">
								{{ $t("online_orders") }}
							</a>
						</li>
						<li>
							<router-link class="menu-item" :to="route + 'contacto'">{{ $t("contact_us") }}</router-link>
						</li>
						<li>
							<a href="javascript:void(0)">{{this.name}}</a>
						</li>
						<li>
							<a @click="logout()" href="javascript:void(0)">{{ $t("logout") }}</a>
						</li>
					</ul>
					<ul class="navbar-desktop" v-if="this.loading">
						<li>
							<a href="javascript:void(0)">{{ $t("closing") }}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="main-elements">
			<div class="container">
				<div class="row">
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav mr-auto" v-if="!this.loading">
							<li class="nav-item active">
								<a :href="route" class="nav-link">{{ $t("online_orders") }}</a>
							</li>
							<li class="nav-item">
								<router-link :to="route + 'contacto'" class="nav-link">{{ $t("contact_us") }}</router-link>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="javascript:void(0)">{{this.name}}</a>
							</li>
							<li class="nav-item mt-5 mb-5">
								<a @click="logout()" href="javascript:void(0)">{{ $t("logout") }}</a>
							</li>
						</ul>
						<ul class="navbar-nav mr-auto" v-if="this.loading">
							<li class="nav-item mt-5 mb-5">
								<a href="javascript:void(0)">{{ $t("closing") }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</header>
	
</template>
<script>
	import { route } from "../../enviroment.js"
	import axios from 'axios'
	export default {
    name: "HeaderMcCain",
    i18n: {
		messages: {
			en: {
				online_orders: "Online Orders",
				contact_us: "Contact Us",
				logout: "Logout",
				closing: "Closing session..."
			},
			es: {
				online_orders: "Pedidos Online",
				contact_us: "Contáctenos",
				logout: "Cerrar Sesión",
				closing: "Cerrarndo Sesión..."
			}
		}
	},
    data() {
        return {
            route: route,
            name: "",
			loading: false
        };
    },
    created() {
        this.getUser();
    },
    methods: {
        getUser() {
			this.name = sessionStorage.getItem('name');
        },
        logout() {
			this.loading = true
            axios.get(this.$woe_api_uri + "/logout")
                .then(() => {
                window.sessionStorage.clear();
				this.loading = false
                location.href = route + "login";
            });
        },
    },
}
</script>