<template>
  <div class="pedido-listado">
    <section class="pedido-steps" v-if="!mostrar_detalle">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="return d-block d-sm-none">
              <router-link :to="route">
                <img src="assets/img/backspace.svg" class="mb-5" alt="backspace">
              </router-link>
            </div>
            <div class="section-title">
              <h5 class="pull-left">{{ $t("title") }}</h5>
              <router-link :to="route">
                <img class="d-none d-sm-block" src="assets/img/backspace.svg" alt="backspace">
              </router-link>
            </div>
          </div>
          <div class="col-12 mt-3">
            <form class="w-100">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>{{ $t("fromDate") }}</label>
                    <Datepicker
                        v-model="filter.fromCreationDate"
                        name="from-creation-date"
                        :enableTimePicker="false"
                        textInput
                        autoApply
                        format="dd/MM/yyyy"
                        inputClassName="dp-input-custom"
                        required
                        utc="preserve"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>{{ $t("toDate") }}</label>
                    <Datepicker
                        v-model="filter.toCreationDate"
                        name="to-creation-date"
                        :enableTimePicker="false"
                        textInput
                        autoApply
                        format="dd/MM/yyyy"
                        inputClassName="dp-input-custom"
                        required
                        utc="preserve"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>{{ $t("order_number") }}</label>
                    <input type="text" name="order-number" v-model="filter.orderNumber" maxlength="34">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>{{ $t("purchase_order") }}</label>
                    <input type="text" name="purchase-order" v-model="filter.purchaseOrder" maxlength="34">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>{{ $t("sap_number") }}</label>
                    <input type="text" name="sap-number" v-model="filter.sapNumber">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>{{ $t("fromDeliveryDate") }}</label>
                    <Datepicker
                        v-model="filter.fromDeliveryDate"
                        name="from-delivery-date"
                        :enableTimePicker="false"
                        textInput
                        autoApply
                        format="dd/MM/yyyy"
                        inputClassName="dp-input-custom"
                        required
                        utc="preserve"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>{{ $t("toDeliveryDate") }}</label>
                    <Datepicker
                        v-model="filter.toDeliveryDate"
                        name="to-delivery-date"
                        :enableTimePicker="false"
                        textInput
                        autoApply
                        format="dd/MM/yyyy"
                        inputClassName="dp-input-custom"
                        required
                        utc="preserve"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>{{ $t("status") }}</label>
                    <select v-model="filter.status">
                      <option selected value="">{{ $t("all") }}</option>
                      <option value="APPROVED">{{ $t("approved") }}</option>
                      <option value="PROCESSING">{{ $t("processing") }}</option>
                      <option value="PENDING">{{ $t("pending") }}</option>
                      <option value="PROCESSED">{{ $t("processed") }}</option>
                      <option value="CANCELED">{{ $t("canceled") }}</option>
                      <option value="INVOICED">{{ $t("invoiced") }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
            <div class="search-row">
              <div class="pull-left order-xs-2">
                <div class="row">
                  <!-- Espaciado para dispositivos grandes y medianos -->
                  <div class="col-md-8 next-btn d-none d-md-flex">
                    <a href="javascript:void(0)" @click="getOrders()" class="btn-green">{{ $t("search") }}</a>
                    <a @click="cleanFilter" href="javascript:void(0)" class="btn-white">{{ $t("clean") }}</a>
                  </div>

                  <!-- Botones para dispositivos pequeños -->
                  <div style="width: 100%;">
                    <div class="col-12 next-btn d-flex d-md-none mb-3">
                      <a href="javascript:void(0)" @click="getOrders()" class="btn-green"
                         style="margin-right: auto; margin-left: auto;">{{ $t("search") }}</a>
                    </div>

                    <div class="col-12 next-btn d-block d-md-none mb-3">
                      <a @click="cleanFilter" href="javascript:void(0)" class="btn-white"
                         style="margin-right: auto; margin-left: auto;">{{ $t("clean") }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pull-right order-xs-1">
                <div class="position-relative">
                  <label class="mr-4 ml-4" data-toggle="modal" data-target="#glosarioModal"><img
                      src="assets/img/help.svg" alt="help"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" id="mostrarTablas">
          <div class="col-md-12">
            <div class="confirmado mt-2">
              <!--Para desktop-->
              <table class="bg-white d-none d-sm-table" id="pedidosTable">
                <thead>
                <tr>
                  <th>{{ $t("date") }}</th>
                  <th class="text-center">{{ $t("order_number") }}</th>
                  <th class="text-center">{{ $t("purchase_order") }}</th>
                  <th class="text-center">{{ $t("sap_number") }}</th>
                  <th class="text-center">{{ $t("ship_to") }}</th>
                  <th class="text-center">{{ $t("selected_delivery_date") }}</th>
                  <th class="text-center">{{ $t("status") }}</th>
                </tr>
                </thead>
                <tbody>
                <tr class="b-top" v-for="(order,index) in this.items" :key="index">
                  <td>{{ order.date_creation }}</td>
                  <td class="text-center">{{ order.order_number }}</td>
                  <td class="text-center">{{ order.purchase_order }}</td>
                  <td class="text-center">{{ order.order_number_sap || '-' }}</td>
                  <td class="text-center">{{ order.delivery_name }}</td>
                  <td class="text-center">{{ order.delivery_date }}</td>
                  <td class="text-center">
                    <div class="inline-flex align-center label-content justify-center">
                      <a href="javascript:void(0)" :class="'label-' + order.state">{{ order.state_name }}</a>
                      <img class="mr-2 ml-2 pointer" @click="verPedido(order.id)" src="assets/img/more.svg" alt="more">
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>

              <!--Para mobile hay que hacer una tabla por pedido-->
              <table class="bg-white d-block d-sm-none pedidosTableMobile b-top" v-for="(order,index) in this.items"
                     :key="index">

                <tr>
                  <th>{{ $t("date") }}</th>
                  <td>{{ order.date_creation }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("order_number") }}</th>
                  <td class="text-center">{{ order.order_number }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("purchase_order") }}</th>
                  <td class="text-center">{{ order.purchase_order }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("sap_number") }}</th>
                  <td class="text-center">{{ order.order_number_sap || '-' }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("ship_to") }}</th>
                  <td class="text-center">{{ order.delivery_name }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("selected_delivery_date") }}</th>
                  <td class="text-center">{{ order.delivery_date }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("status") }}</th>
                  <td class="text-center">
                    <div class="inline-flex align-center label-content justify-center">
                      <a href="javascript:void(0)" :class="'label-' + order.state">{{ order.state_name }}</a>
                      <img class="mr-2 ml-2 pointer" @click="verPedido(order.id)" src="assets/img/more.svg" alt="more">
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="loading d-none"></div>
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <Vue3BsPaginate
                :total="total"
                v-model="currentPage"
                :perPage=perPage
                alignment="center"
            />
          </div>
        </div>
      </div>
    </section>
    <GlosarioModal></GlosarioModal>
    <PedidoDetalle v-if="mostrar_detalle" :order_id="this.order_id"
                   @ocultarDetalleChild="ocultarDetalle"></PedidoDetalle>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert"/>
  </div>
</template>

<script>
import GlosarioModal from '../layouts/GlosarioModal.vue'
import PedidoDetalle from './PedidoDetalle.vue'
import {route} from "../../enviroment.js"
// import axios from 'axios'
import $ from 'jquery'
import {Vue3BsPaginate} from 'vue3-bootstrap-paginate';
import axiosInstance from '../../main';
import authMixin from '@/mixins/authMixin';

export default {
  name: 'PedidosListado',
  i18n: {
    messages: {
      en: {
        title: "List of orders sent to McCain for processing.",
        status: "Status",
        all: "All",
        approved: "Approved",
        processing: "Processing",
        pending: "Pending",
        processed: "Approval Pending",
        canceled: "Canceled",
        invoiced: "Invoiced",
        date: "Date",
        fromDate: "From Date",
        toDate: "To Date",
        order_number: "Order Number",
        purchase_order: "Purchase Order",
        sap_number: "SAP Number",
        ship_to: "Ship To",
        selected_delivery_date: "Selected Delivery Date",
        fromDeliveryDate: "From Delivery Date",
        toDeliveryDate: "To Delivery Date",
        search: "Search",
        clean: "Clean",
      },
      es: {
        title: "Listado de los pedidos que fueron enviados a McCain para su procesamiento.",
        status: "Estado",
        all: "Todos",
        approved: "Aprobado",
        processing: "Enviando",
        pending: "Pendiente",
        processed: "Aprobación Pendiente",
        canceled: "Cancelada",
        invoiced: "Facturado",
        date: "Fecha",
        fromDate: "Fecha desde",
        toDate: "Fecha hasta",
        order_number: "Número Pedido",
        purchase_order: "Purchase order",
        sap_number: "Número SAP",
        ship_to: "Lugar de entrega",
        selected_delivery_date: "Fecha requerida de entrega",
        fromDeliveryDate: "Fecha de entrega desde",
        toDeliveryDate: "Fecha de entrega hasta",
        search: "Buscar",
        clean: "Limpiar",
      }
    }
  },
  components: {
    GlosarioModal,
    PedidoDetalle,
    Vue3BsPaginate
  },
  data: function () {
    return {
      route: route,
      key: '',
      perPage: 10,
      currentPage: 1,
      orders: [],
      order_id: '',
      mostrar_detalle: false,
      filter: {
        fromCreationDate: '',
        toCreationDate: '',
        fromDeliveryDate: '',
        toDeliveryDate: '',
        orderNumber: '',
        purchaseOrder: '',
        sapNumber: '',
        status: '',
      },
    }
  },
  computed: {
    total() {
      return this.orders.length
    },
    items() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.orders.slice(startIndex, endIndex);
    },
  },
  mixins: [authMixin],
  async created() {
    await this.validateUserAndSoldTo();
    await this.setInitialCreationDate();
    this.getOrders();
  },
  methods: {
    showAlert(type, title, message) {
      this.$refs.alert.showAlert(
          type, // There are 4 types of alert: success, info, warning, error
          message, // Message of the alert
          title, // Header of the alert
          {
            iconSize: 35, // Size of the icon (px)
            iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
            position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
          }
      )
    },
    mostrarCarga() {
      $('.loading').removeClass('d-none');
      $('#mostrarTablas').addClass('d-none');
    },
    ocultarCarga() {
      $('.loading').addClass('d-none');
      $('#mostrarTablas').removeClass('d-none');
    },
    cleanFilter() {
      this.filter = {
        fromCreationDate: '',
        toCreationDate: '',
        fromDeliveryDate: '',
        toDeliveryDate: '',
        orderNumber: '',
        purchaseOrder: '',
        sapNumber: '',
        status: '',
      };
      this.getOrders();
    },
    getFilterData() {
      let filterObject = JSON.parse(JSON.stringify(this.filter));

      if (filterObject.fromCreationDate !== undefined && filterObject.fromCreationDate !== 'null' && filterObject.fromCreationDate !== null && !isNaN(Date.parse(filterObject.fromCreationDate))) {
        filterObject.fromCreationDate = (new Date(filterObject.fromCreationDate)).toISOString().split('T')[0];
      } else if (isNaN(Date.parse(filterObject.fromCreationDate))) filterObject.fromCreationDate = '';

      if (filterObject.toCreationDate !== undefined && filterObject.toCreationDate !== 'null' && filterObject.toCreationDate !== null && !isNaN(Date.parse(filterObject.toCreationDate))) {
        filterObject.toCreationDate = (new Date(filterObject.toCreationDate)).toISOString().split('T')[0];
      } else if (isNaN(Date.parse(filterObject.toCreationDate))) filterObject.fromCreationDate = '';

      if (filterObject.fromDeliveryDate !== undefined && filterObject.fromDeliveryDate !== 'null' && filterObject.fromDeliveryDate !== null && !isNaN(Date.parse(filterObject.fromDeliveryDate))) {
        filterObject.fromDeliveryDate = (new Date(filterObject.fromDeliveryDate)).toISOString().split('T')[0];
      } else if (isNaN(Date.parse(filterObject.fromDeliveryDate))) filterObject.fromDeliveryDate = '';

      if (filterObject.toDeliveryDate !== undefined && filterObject.toDeliveryDate !== 'null' && filterObject.toDeliveryDate !== null && !isNaN(Date.parse(filterObject.toDeliveryDate))) {
        filterObject.toDeliveryDate = (new Date(filterObject.toDeliveryDate)).toISOString().split('T')[0];
      } else if (isNaN(Date.parse(filterObject.toDeliveryDate))) filterObject.toDeliveryDate = '';

      return filterObject;
    },
    getFilterQuery() {
      const query = new URLSearchParams(this.getFilterData()).toString();
      return (query) ? `&${query}` : ``;
    },
    setInitialCreationDate() {
      const currentDate = new Date();
      const sixMonthsAgoDate = new Date();
      sixMonthsAgoDate.setMonth(currentDate.getMonth() - 6);
      this.filter.fromCreationDate = sixMonthsAgoDate;
      this.filter.toCreationDate = currentDate;
    },
    getOrders(page = 1) {
      this.mostrarCarga();
      axiosInstance.get(this.$woe_api_uri + `/order?page=${page}${this.getFilterQuery()}`)
          .then((response) => {
            this.orders = response.data.data.orders;
            this.currentPage = 1;
            this.ocultarCarga();
          })
          .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
              this.showAlert('warning', `${this.$t("caution")}!`, error.response.data.error.message);
              setTimeout(() => {
                this.$router.push('/');
              }, 5000);
            } else {
              this.showAlert('warning', `${this.$t("caution")}!`, error.response.data.error.message);
            }
          });
    },
    ocultarDetalle(variable) {
      this.mostrar_detalle = variable;
      this.getOrders();
    },
    verPedido(id) {
      this.mostrar_detalle = true;
      this.order_id = id;
    },
  }
}
</script>
<style>
.pagination li {
  margin: 0 !important;
}
</style>