<template>
   <div class="modal fade" id="glosarioModal" tabindex="-1" role="dialog" aria-labelledby="glosarioModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="glosarioModal">{{ $t("title") }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <ul>
                    <li>
                        <div class="label-content">
                            <a class="label-CANCELED" href="">{{ $t("canceled") }}</a>
                        </div>
                        <span>{{ $t("canceled_text") }}</span>
                    </li>
                    <li>
                        <div class="label-content">
                            <a class="label-PENDING" href="">{{ $t("pending") }}</a>
                        </div>
                        <span>{{ $t("pending_text") }}</span>
                    </li>
                    <li>
                        <div class="label-content">
                            <a class="label-PROCESSING" href="">{{ $t("processing") }}</a>
                        </div>
                        <span>{{ $t("processing_text") }}</span>
                    </li>
                    <li>
                        <div class="label-content">
                            <a class="label-PROCESSED" href="">{{ $t("processed") }}</a>
                        </div>
                        <span>{{ $t("processed_text") }}</span>
                    </li>
                    <li>
                        <div class="label-content">
                            <a class="label-APPROVED" href="">{{ $t("approved") }}</a>
                        </div>
                        <span>{{ $t("approved_text") }}</span>
                    </li>
                    <li>
                        <div class="label-content">
                            <a class="label-INVOICED" href="">{{ $t("invoiced") }}</a>
                        </div>
                        <span>{{ $t("invoiced_text") }}</span>
                    </li>
                </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "GlosarioModal",
        i18n: {
            messages: {
                en: { 
                    title: "Label Glossary",
                    canceled: "Canceled",
                    canceled_text: "Canceled by the seller",
                    pending: "Pending",
                    pending_text: "Awaiting approval",
                    processing: "Sending",
                    processing_text: "The order is being sent from the Portal to McCain",
                    processed: "Pending Approval",
                    processed_text: "The order is being validated in SAP McCain",
                    approved: "Approved",
                    approved_text: "The order has been approved by Credits",
                    invoiced: "Invoicing in progress",
                    invoiced_text: "The order is being invoiced for subsequent delivery."
                },
                es: {
                    title: "Glosario de etiquetas",
                    canceled: "Cancelada",
                    canceled_text: "Cancelada por el vendedor",
                    pending: "Pendiente",
                    pending_text: "Esperando aprobación",
                    processing: "Enviando",
                    processing_text: "El pedido se esta enviándo desde el Portal a McCain",
                    processed: "Pendiente de Aprobacion",
                    processed_text: "El pedido está siendo validado en SAP McCain.",
                    approved: "Aprobado",
                    approved_text: "El pedido se ha aprobado por Créditos",
                    invoiced: "En proceso de facturacion",
                    invoiced_text: "El pedido está en instancia de facturación para su posterior entrega.",
                }
            }
        },
        data() {
            return {}
        },
    }
</script>