<template>
    <div class="pedido-listado">
        <section class="pedido-list">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="return d-block">
                            <router-link :to="route">
                                <img src="assets/img/backspace.svg" class="mb-5">
                            </router-link>
                            <router-link :to="route + 'change-order-new'">
                                <a href="javascript:void(0)" class="btn-green mr-0 ml-2 btn-new-mobile">{{ $t("title") }}</a>
                            </router-link>
                        </div>
                        <div class="section-title">
                            <h5 class="pull-left">{{ $t("text") }}</h5>
                        </div>
                    </div>
                </div>
                <div class="row" id="mostrarTablas">
                    <div class="col-md-12">
                        <div class="confirmado mt-2">
                            <!--Para desktop-->
                            <table class="bg-white d-none d-sm-table" id="pedidosTable">
                                <thead>
                                    <tr>
                                        <th>{{ $t("date") }}</th>
                                        <th class="text-center">{{ $t("order_number") }}</th>
                                        <th class="text-center">{{ $t("type_request") }}</th>
                                        <th class="text-center">{{ $t("description") }}</th>
                                        <th class="text-center">{{ $t("status") }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="b-top" v-for="(order_change,index) in this.order_changes" :key="index">
                                        <td>{{order_change.creation_date}}</td>
                                        <td class="text-center">{{order_change.order_number}}</td>
                                        <td class="text-center">{{order_change.change_type}}</td>
                                        <td class="text-center">{{order_change.description}}</td>
                                        <td class="text-center">
                                            <div class="inline-flex align-center label-content justify-center">
                                                <a href="javascript:void(0)" :class="'label-order-change-' + order_change.status">{{order_change.status_name}}</a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <!--Para mobile hay que hacer una tabla por pedido-->
                            <table class="bg-white d-block d-sm-none pedidosTableMobile b-top" v-for="(order_change,index) in this.order_changes" :key="index">
                                <tr>
                                    <th>{{ $t("date") }}</th>
                                    <td>{{order_change.creation_date}}</td>
                                </tr>
                                <tr>
                                    <th class="text-center">{{ $t("order_number") }}</th>
                                    <td class="text-center">{{order_change.order_number}}</td>
                                </tr>
                                <tr>
                                    <th class="text-center">{{ $t("type_request") }}</th>
                                    <td class="text-center">{{order_change.change_type}}</td>
                                </tr>
                                <tr>
                                    <th class="text-center">{{ $t("description") }}</th>
                                    <td class="text-center">{{order_change.description}}</td>
                                </tr>
                                <tr>
                                    <th class="text-center">{{ $t("status") }}</th>
                                    <td class="text-center">
                                        <div class="inline-flex align-center label-content justify-center">
                                            <a href="javascript:void(0)" :class="'label-order-change-' + order_change.status">{{order_change.status_name}}</a>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="loading"></div>
                <div class="row">
                    <div class="col-md-12 text-center mb-5"> 
                        <ul class="pagination">
                            <li @click="filtrarPedidos('page',index)" v-for="index in this.total_paginas" :key="index">
                                <span class="active" v-if="(index == this.filtro_page)">{{index}}</span>
                                <span v-else>{{index}}</span>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
        <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
    </div>
</template>

<script>
	import { route } from "../../enviroment.js" 
    // import axios from 'axios'
    import $ from 'jquery'
    import axiosInstance from '../../main';
    import authMixin from '@/mixins/authMixin';

    export default {
        name: 'PedidosCambiosListado',
        data: function () {
            return {
                order_changes: [],             
                key: '',
                route : route,
                filtro_page: 1,
                total_paginas: '',
            }
        },
        i18n: {
            messages: {
                en: {
                    title: "New Request",
                    text: "List of order change requests.",
                    date: "Date",
                    order_number: "Order Number",
                    type_request: "Request Type",
                    description: "Description",
                    status: "Status"
                },
                es: {
                    title: "Nueva Solicitud",
                    text: "Listado de las solicitudes de cambios de ordenes.",
                    date: "Fecha",
                    order_number: "Número Orden",
                    type_request: "Tipo Solicitud",
                    description: "Descripcion",
                    status: "Estado"
                }
            }
        },
        mixins: [authMixin],
        async created(){
            await this.validateUserAndSoldTo();
            this.getChangeOrders()
        },        
        methods:{
            showAlert(type, title, message) {
                this.$refs.alert.showAlert(
                    type, // There are 4 types of alert: success, info, warning, error
                    message, // Message of the alert
                    title, // Header of the alert
                    { 
                        iconSize: 35, // Size of the icon (px)
                        iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                        position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                    } 
                )
            },
            filtrarPedidos(filter,event){
                this.mostrarCarga()
                if (filter == 'page'){
                    this.filtro_page = event
                }
                this.getChangeOrders()
            },
            mostrarCarga(){
                $('.loading').removeClass('d-none');
                $('#mostrarTablas').addClass('d-none');
            },
            ocultarCarga(){
                $('.loading').addClass('d-none');
                $('#mostrarTablas').removeClass('d-none');
            },
            getChangeOrders(){
                axiosInstance.get(`${this.$woe_api_uri}/order-change/${this.filtro_page}/10`)
                .then((response) => {
                    this.ocultarCarga()
                    this.order_changes = response.data.data.order_changes
                    this.total_paginas = response.data.data.paginator.pages
                })
                .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href = "/login";
                    } else {
                        this.showAlert('warning',`${this.$t("caution")}!`, error.response.data.error.message);
                    }
                });
            },
        }
    }
</script>