<template>
  <div id="app" class="main-container">
    <HeaderMcCain v-if="$route.path != route + 'login'"></HeaderMcCain>
    <router-view></router-view>
    <FooterCopyright v-if="$route.path != route + 'login'"></FooterCopyright>
  </div>
</template>

<script>
import FooterCopyright from './components/layouts/FooterCopyright.vue'
import HeaderMcCain from './components/layouts/HeaderMcCain.vue'
import { route } from './enviroment.js'
export default {
  name: 'app',
  components: { 
    HeaderMcCain,
    FooterCopyright
   },
    data() {
        return {
            route: route
        }
    }
   
}
</script>